import React, { useEffect, useRef, useState } from "react";
import {
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Table,
  Container,
  Row,
  Spinner,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
} from "reactstrap";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";

// core components
import Header from "components/Headers/Header.js";
import db from "config/firebase";
import "./sidebar.css";
import "firebase/auth";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import { storeUserData, updateLinked, clearReduxData } from "store/action";
import axios from "axios";
import { FcGoogle } from "react-icons/fc";
import { FaFacebookSquare, FaTwitter, FaPhoneAlt } from "react-icons/fa";
import { RiLockPasswordFill, RiAppleFill } from "react-icons/ri";

const User = () => {
  const [user, setUsers] = useState();
  const [loader, setLoader] = useState(true);
  const [modal, setModal] = useState(false);
  const [editId, setEditId] = useState();
  const [editDisplayName, setEditDisplayName] = useState();
  const [editPhone, setEditPhone] = useState();
  const [editCreatedTime, setEditCreatedTime] = useState();
  const [editIndex, setEditIndex] = useState();
  const [defaultrowCount, setDefaultRowCount] = useState();
  const [rowCount, setRowCount] = useState(0);
  const [pageNum, setPageNum] = useState(1);
  const [totalPage, setTotalPage] = useState(20);
  const [dropDownPage, setDropDownPage] = useState(1);
  const dispatch = useDispatch();
  const toggle = () => setModal(!modal);
  const history = useHistory();
  const allData = useSelector((state) => state);
  const displayInput = useRef();
  const phoneInput = useRef();

  useEffect(async () => {
    if (allData.role !== "") {
      if (!allData.userData?.length) {
        setLoader(true);
        let temp = [];

        db.collection("users")
          .get()
          .then(async (collections) => {
            collections.docs.forEach(async (doc) => {
              temp.push(doc.data());
            });
            setUsers(temp);
            setDefaultRowCount(user?.length);
            dispatch(storeUserData(temp));
            setLoader(false);

            for (let i = 0; i < collections.docs?.length; i++) {
              const obj = { email: collections.docs[i].data().email };
              try {
                let res = await axios.post(
                  "https://us-central1-zonar-app-prod.cloudfunctions.net/app",
                  obj
                );

                let data = {
                  ...collections.docs[i].data(),
                  providerData: res?.data?.providerData || [],
                };

                dispatch(updateLinked(data));
              } catch (error) {
                console.log(error);
              }
            }
          });
      } else {
        setUsers(allData.userData);

        setLoader(false);

        let remainig = allData.userData.filter((i) => !i.providerData);

        for (let i = 0; i < remainig.length; i++) {
          const obj = { email: remainig[i].email };
          try {
            let res = await axios.post(
              "https://us-central1-zonar-app-prod.cloudfunctions.net/app",
              obj
            );
            let data = {
              ...remainig[i],
              providerData: res?.data?.providerData || [],
            };
            dispatch(updateLinked(data));
          } catch (error) {
            let data = {
              ...remainig[i],
              providerData: [],
            };
            dispatch(updateLinked(data));
          }
        }
      }
    } else {
      history.push("/");
    }
  }, []);

  useEffect(() => {
    setUsers(allData.userData);
    setDefaultRowCount(allData.userData.length);
  }, [allData.userData]);

  const roleAdmin = async (id, index) => {
    await db
      .collection("users")
      .doc(id)
      .update({
        role: "Admin",
      })
      .then(() => {
        let temp = [...user];
        temp[index].role = "Admin";
        setUsers(temp);
        dispatch(storeUserData(temp));
      });
    Swal.fire({
      icon: "success",
      text: "Assigned Role to Admin",
    });
  };
  const roleUser = (id, index) => {
    db.collection("users")
      .doc(id)
      .update({
        role: "User",
      })
      .then(() => {
        let temp = [...user];

        temp[index].role = "User";
        setUsers(temp);
        dispatch(storeUserData(temp));
      });
    Swal.fire({
      icon: "success",
      text: "Assigned Role to User",
    });
  };

  const deleteData = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this data!",
      icon: "warning",
      dangerMode: true,
      showCancelButton: true,
      closeOnConfirm: false,
      closeOnCancel: false,
    }).then((result) => {
      if (result.isConfirmed) {
        let temp = user.filter((item) => item.docId !== id);
        setUsers(temp);
        dispatch(storeUserData(temp));
        db.collection("users")
          .doc(id)
          .delete()
          .then(() => {
            Swal.fire({
              title: "Shortlisted!",
              text: "Data deleted successfully!",
              icon: "success",
            });
          })
          .catch((error) => {
            console.error("Error removing document: ", error);
          });
      } else {
        Swal.fire({
          title: "Cancelled",
          text: "Your data is safe :)",
          icon: "error",
        });
      }
    });
  };

  const editData = (id, displayName, phone, createdAt, index) => {
    toggle();
    setEditId(id);
    setEditDisplayName(displayName);
    setEditPhone(phone);
    setEditIndex(index);
    let createdDuration = new Date(createdAt).toLocaleString();
    setEditCreatedTime(createdDuration);
  };

  useEffect(() => {
    if (displayInput.current) {
      displayInput.current.value = editDisplayName;
    }
    if (phoneInput.current) {
      phoneInput.current.value = editPhone;
    }
  }, [displayInput.current, editDisplayName, phoneInput.current, editPhone]);

  const updateData = () => {
    db.collection("users")
      .doc(editId)
      .update({
        displayName: displayInput?.current?.value,
        phone: phoneInput?.current?.value,
      })
      .then(() => {
        let temp = [...user];
        temp[editIndex].displayName = displayInput?.current?.value;
        temp[editIndex].phone = phoneInput?.current?.value;
        setUsers(temp);
        toggle();
        dispatch(storeUserData(temp));
      });
  };

  const columns = [
    {
      dataField: "displayName",
      text: "Full Name",
      sort: true,
      filter: textFilter(),
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
      filter: textFilter(),
    },
    {
      dataField: "phone",
      text: "Phone Number",
      sort: true,
      filter: textFilter(),
    },
    {
      dataField: "linked_accounts",
      text: "Linked Accounts",
      sort: true,
      filter: textFilter(),
      filterValue: (cell, row) => cell,
      formatter: (cell, row) => {
        return (
          <div className="myStyles">
            {cell == "Google" ? (
              <FcGoogle size={20} />
            ) : "" || cell == "Password" ? (
              <RiLockPasswordFill size={20} />
            ) : "" || cell == "Apple" ? (
              <RiAppleFill size={20} />
            ) : "" || cell == "Facebook" ? (
              <FaFacebookSquare size={20} />
            ) : "" || cell == "Twitter" ? (
              <FaTwitter size={20} />
            ) : "" || cell == "Phone" ? (
              <FaPhoneAlt size={20} />
            ) : (
              cell?.length &&
              cell?.map((item) => {
                if (item == "Google") {
                  return <FcGoogle size={20} />;
                } else if (item == "Password") {
                  return <RiLockPasswordFill size={20} />;
                } else if (item == "Apple") {
                  return <RiAppleFill size={20} />;
                } else if (item == "Facebook") {
                  return <FaFacebookSquare size={20} />;
                } else if (item == "Twitter") {
                  return <FaTwitter size={20} />;
                } else if (item == "Phone") {
                  return <FaPhoneAlt size={20} />;
                }
              })
            )}
          </div>
        );
      },
    },
    {
      dataField: "date_time",
      text: "Created Date & Time",
      sort: true,
      filter: textFilter(),
    },
    {
      dataField: "lastLogin",
      text: "Last Login Date & Time",
      sort: true,
      filter: textFilter(),
    },
    {
      dataField: "role",
      text: "Role",
    },
    {
      dataField: "action",
      text: "Action",
    },
  ];

  // const pagination = paginationFactory({
  //   page: pageNum,
  //   sizePerPage: 20,
  //   // paginationSize: 33,
  //   pageStartIndex: dropDownPage,
  //   hideSizePerPage: false,
  //   nextPageText: ">",
  //   prePageText: "<",
  //   alwaysShowAllBtns: true,
  //   withFirstAndLast: true,
  //   // showTotal: true,

  //   onSizePerPageChange: function (sizePerPage, page) {
  //     setPageNum(page);
  //     setTotalPage(sizePerPage);
  //   },

  //   onPageChange: function (page, sizePerPage) {
  //     setPageNum(page);
  //   },

  //   sizePerPageList:
  //     allData?.userData?.length > 100
  //       ? [
  //           {
  //             text: "10",
  //             value: 10,
  //           },
  //           {
  //             text: "20",
  //             value: 20,
  //           },
  //           {
  //             text: "30",
  //             value: 30,
  //           },
  //           {
  //             text: "40",
  //             value: 40,
  //           },
  //           {
  //             text: "50",
  //             value: 50,
  //           },
  //           {
  //             text: "60",
  //             value: 60,
  //           },
  //           {
  //             text: "70",
  //             value: 70,
  //           },
  //           {
  //             text: "80",
  //             value: 80,
  //           },
  //           {
  //             text: "90",
  //             value: 90,
  //           },
  //           {
  //             text: "100",
  //             value: 100,
  //           },
  //           {
  //             text: "All",
  //             value: allData?.userData?.length,
  //           },
  //         ]
  //       : allData?.userData?.length < 100 && allData?.userData?.length > 50
  //       ? [
  //           {
  //             text: "10",
  //             value: 10,
  //           },
  //           {
  //             text: "20",
  //             value: 20,
  //           },
  //           {
  //             text: "10",
  //             value: 30,
  //           },
  //           {
  //             text: "10",
  //             value: 40,
  //           },
  //           {
  //             text: "10",
  //             value: 50,
  //           },
  //           {
  //             text: "All",
  //             value: allData?.userData?.length,
  //           },
  //         ]
  //       : allData?.userData?.length < 50 && allData?.userData?.length > 20
  //       ? [
  //           {
  //             text: "10",
  //             value: 10,
  //           },
  //           {
  //             text: "20",
  //             value: 20,
  //           },
  //           {
  //             text: "All",
  //             value: allData?.userData?.length,
  //           },
  //         ]
  //       : [
  //           {
  //             text: "10",
  //             value: 10,
  //           },
  //           {
  //             text: "All",
  //             value: allData?.userData.length,
  //           },
  //         ],
  // });

  const pageListRenderer = ({ pages, onPageChange }) => {
    const pageWithoutIndication = pages.filter(
      (p) => typeof p.page !== "string"
    );
    return (
      <div>
        <UncontrolledDropdown id="page-dropdown">
          <DropdownToggle
            caret
            color="secondary"
            id="dropdownMenuButton"
            type="button"
          >
            {dropDownPage}
          </DropdownToggle>
          <DropdownMenu
            aria-labelledby="dropdownMenuButton"
            style={{
              maxHeight: 280,
              overflowY: "auto",
            }}
          >
            {pageWithoutIndication.map((p) => (
              <DropdownItem
                onClick={() => {
                  onPageChange(p.page);
                  setDropDownPage(p.page);
                }}
              >
                {p.page}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </UncontrolledDropdown>
      </div>
    );
  };

  const options = {
    pageListRenderer,
    sizePerPage: 20,
    paginationSize: allData?.userData?.length,

    onSizePerPageChange: function (sizePerPage, page) {
      setPageNum(page);
      setTotalPage(sizePerPage);
    },

    onPageChange: function (page, sizePerPage) {
      setPageNum(page);
    },

    sizePerPageList:
      allData?.userData?.length > 100
        ? [
            {
              text: "10",
              value: 10,
            },
            {
              text: "20",
              value: 20,
            },
            {
              text: "30",
              value: 30,
            },
            {
              text: "40",
              value: 40,
            },
            {
              text: "50",
              value: 50,
            },
            {
              text: "60",
              value: 60,
            },
            {
              text: "70",
              value: 70,
            },
            {
              text: "80",
              value: 80,
            },
            {
              text: "90",
              value: 90,
            },
            {
              text: "100",
              value: 100,
            },
            {
              text: "All",
              value: allData?.userData?.length,
            },
          ]
        : allData?.userData?.length < 100 && allData?.userData?.length > 50
        ? [
            {
              text: "10",
              value: 10,
            },
            {
              text: "20",
              value: 20,
            },
            {
              text: "10",
              value: 30,
            },
            {
              text: "10",
              value: 40,
            },
            {
              text: "10",
              value: 50,
            },
            {
              text: "All",
              value: allData?.userData?.length,
            },
          ]
        : allData?.userData?.length < 50 && allData?.userData?.length > 20
        ? [
            {
              text: "10",
              value: 10,
            },
            {
              text: "20",
              value: 20,
            },
            {
              text: "All",
              value: allData?.userData?.length,
            },
          ]
        : [
            {
              text: "10",
              value: 10,
            },
            {
              text: "All",
              value: allData?.userData.length,
            },
          ],
  };

  const defaultSorted = [
    {
      dataField: "name",
      order: "desc",
    },
  ];
  const handleDataChange = ({ dataSize }) => {
    if (dataSize == "0") {
      setRowCount(false);
      setDropDownPage(1);
    } else {
      setRowCount(dataSize);
      setDropDownPage(pageNum);
    }
  };
  const myData = user?.map((user, index) => ({
    displayName: user?.displayName ? user?.displayName : "N/A",
    email: user?.email ? user?.email : "N/A",
    phone: user?.phone ? user?.phone : "N/A",
    linked_accounts: user?.providerData?.length ? (
      user.providerData.map((data) =>
        data?.providerId == "google.com"
          ? "Google"
          : "" || data?.providerId == "password"
          ? "Password"
          : "" || data?.providerId == "apple.com"
          ? "Apple"
          : "" || data?.providerId == "facebook.com"
          ? "Facebook"
          : "" || data?.providerId == "twitter.com"
          ? "Twitter"
          : "" || data?.providerId == "phone"
          ? "Phone"
          : ""
      )
    ) : (
      <>
        <div className="spinner">
          <div className="bounce1"></div>
          <div className="bounce2"></div>
          <div className="bounce3"></div>
        </div>
      </>
    ),

    date_time: user?.createdAt
      ? new Date(user.createdAt).toLocaleString()
      : "N/A",

    lastLogin: user?.lastLogin
      ? new Date(user?.lastLogin).toDateString()
      : "N/A",
    role: (
      <UncontrolledDropdown>
        {user?.role ? (
          <DropdownToggle
            caret
            color="secondary"
            id="dropdownMenuButton"
            type="button"
          >
            {user?.role}
          </DropdownToggle>
        ) : (
          <DropdownToggle
            caret
            color="secondary"
            id="dropdownMenuButton"
            type="button"
          >
            User
          </DropdownToggle>
        )}
        <DropdownMenu aria-labelledby="dropdownMenuButton">
          {user?.role === "Admin" ? (
            <DropdownItem onClick={() => roleUser(user.docId, index)}>
              User
            </DropdownItem>
          ) : (
            <DropdownItem onClick={() => roleAdmin(user.docId, index)}>
              Admin
            </DropdownItem>
          )}
        </DropdownMenu>
      </UncontrolledDropdown>
    ),
    action: (
      <UncontrolledDropdown>
        <DropdownToggle
          className="btn-icon-only text-light"
          href="#"
          role="button"
          size="sm"
          color=""
          onClick={(e) => e.preventDefault()}
        >
          <i className="fas fa-ellipsis-v" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-arrow" right>
          <DropdownItem
            href="#pablo"
            onClick={() =>
              editData(
                user.docId,
                user.displayName,
                user.phone,
                user.createdAt,
                index
              )
            }
          >
            Edit
          </DropdownItem>
          <DropdownItem href="#pablo" onClick={() => deleteData(user.docId)}>
            Delete
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    ),
  }));

  const refresh = () => {
    dispatch(clearReduxData());
    setLoader(true);
    let temp = [];
    db.collection("users")
      .get()
      .then(async (collections) => {
        collections.docs.forEach(async (doc) => {
          temp.push(doc.data());
        });
        setUsers(temp);
        setDefaultRowCount(user?.length);
        dispatch(storeUserData(temp));
        setLoader(false);

        for (let i = 0; i < collections.docs?.length; i++) {
          const obj = { email: collections.docs[i].data().email };
          try {
            let res = await axios.post(
              "https://us-central1-zonar-app-prod.cloudfunctions.net/app",
              obj
            );

            let data = {
              ...collections.docs[i].data(),
              providerData: res?.data?.providerData || [],
            };

            dispatch(updateLinked(data));
          } catch (error) {
            console.log(error);
          }
        }
      });
  };
  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Row id="user">
          <div className="col">
            <Card className="shadow">
              <CardHeader className="table-header" id="dektp_view">
                <h3 className="mb-0">
                  User List{" "}
                  <button
                    onClick={refresh}
                    className="btn btn-primary py-1 px-1 ml-2"
                  >
                    Refresh
                  </button>
                </h3>

                {/* {rowCount > 0 || rowCount === false ? ( */}
                <div className="d-flex table-wrapper">
                  <h3 className="records-title">
                    {" "}
                    No. of Records:<span className="dyn_drop"></span>
                  </h3>

                  <h3 className="page-title">
                    Pages:
                    <span
                      className={
                        rowCount > 0 || rowCount === false
                          ? "dyn_drop_"
                          : "dyn_drop"
                      }
                    ></span>
                    /{" "}
                    {rowCount > 0 || rowCount === false
                      ? Math.ceil(rowCount / 20) == 0
                        ? "0"
                        : ` ${Math.ceil(rowCount / totalPage)}`
                      : Math.ceil(defaultrowCount / totalPage)}
                  </h3>
                  <h3 className="total-title">
                    {" "}
                    Total:{" "}
                    {rowCount > 0 || rowCount === false
                      ? rowCount
                        ? rowCount
                        : "0"
                      : defaultrowCount}
                  </h3>
                </div>
              </CardHeader>
              {/* //mobile view */}
              <CardHeader className="table-header" id="mob_view">
                <h3 className="mb-0 text-center">User Lists </h3>

                <h3 className="records-title mt-3 mb-3">
                  {" "}
                  No. of Records:<span className="dyn_drop"></span>
                </h3>

                <h3 className="page-title">
                  Pages:
                  <span
                    className={
                      rowCount > 0 || rowCount === false
                        ? "dyn_drop_"
                        : "dyn_drop"
                    }
                  ></span>
                  /{" "}
                  {rowCount > 0 || rowCount === false
                    ? Math.ceil(rowCount / 20) == 0
                      ? "0"
                      : ` ${Math.ceil(rowCount / totalPage)}`
                    : Math.ceil(defaultrowCount / totalPage)}
                </h3>
                <h3 className="total-title">
                  {" "}
                  Total:{" "}
                  {rowCount > 0 || rowCount === false
                    ? rowCount
                      ? rowCount
                      : "0"
                    : defaultrowCount}
                </h3>
                <button
                  onClick={refresh}
                  className="btn btn-primary py-1 px-1 ml-2"
                >
                  Refresh
                </button>
              </CardHeader>

              {loader ? (
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Full Name</th>
                      <th scope="col">Email</th>
                      <th scope="col">Phone Number</th>
                      <th scope="col">Linked Accounts</th>
                      <th scope="col">CREATED DATE & TIME</th>
                      <th>LAST LOGIN DATE & TIME</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="text-center">
                      <td className="loader" colSpan={7}>
                        <Spinner className="text-primary">
                          <span className=" sr-only">Loading...</span>
                        </Spinner>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              ) : (
                <>
                  <BootstrapTable
                    bootstrap4
                    keyField="id"
                    data={myData}
                    columns={columns}
                    defaultSorted={defaultSorted}
                    bordered={false}
                    pagination={paginationFactory(options)}
                    filter={filterFactory()}
                    onDataSizeChange={handleDataChange}
                  />
                  {/* <BootstrapTable
                    bootstrap4
                    keyField="id"
                    data={myData}
                    columns={columns}
                    defaultSorted={defaultSorted}
                    bordered={false}
                    pagination={pagination }
                    filter={filterFactory()}
                    onDataSizeChange={handleDataChange}
                  /> */}
                </>
              )}
              {/* <CardFooter></CardFooter> */}
            </Card>
          </div>
        </Row>
      </Container>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          <h2>Edit</h2>
        </ModalHeader>
        <ModalBody>
          <Label>Diplay Name</Label>
          <Input type="text" innerRef={displayInput} />
          <Label>Phone </Label>
          <Input type="number" innerRef={phoneInput} />

          <Label>Created At </Label>
          <Input type="text" value={editCreatedTime} disabled />
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={updateData}>
            Update
          </Button>{" "}
        </ModalFooter>
      </Modal>
    </>
  );
};

export default React.memo(User);
