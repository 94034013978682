/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import User from './components/SidebarContent/User';
import Providers from './components/SidebarContent/Providers';
import Review from './components/SidebarContent/Review';
import Login from './pages/Login';

var routes = [
  // {
  //   path: "/index",
  //   name: "Dashboard",
  //   icon: "ni ni-tv-2 text-primary",
  //   component: Index,
  //   layout: "/admin",
  // },
  // {
  //   path: "/icons",
  //   name: "Icons",
  //   icon: "ni ni-planet text-blue",
  //   component: Icons,
  //   layout: "/admin",
  // },
  // {
  //   path: "/maps",
  //   name: "Maps",
  //   icon: "ni ni-pin-3 text-orange",
  //   component: Maps,
  //   layout: "/admin",
  // },
  // {
  //   path: "/user-profile",
  //   name: "User Profile",
  //   icon: "ni ni-single-02 text-yellow",
  //   component: Profile,
  //   layout: "/admin",
  // },
  {
    path: '/users',
    name: 'Users',
    icon: 'ni ni-single-02 text-yellow',
    component: User,
    layout: '/admin',
  },
  {
    path: '/providers',
    name: 'Providers',
    icon: 'ni ni-collection text-red ',
    component: Providers,
    layout: '/admin',
  },
  {
    path: '/reviews',
    name: 'Reviews',
    icon: 'ni ni-favourite-28 text-green',
    component: Review,
    layout: '/admin',
  },
  {
    path: '/',
    component: Login,
    layout: '/auth',
  },
  // {
  //   path: "/",
  //   name: "Register",
  //   icon: "ni ni-circle-08 text-pink",
  //   component: Register,
  //   layout: "/auth",
  // },
];
export default routes;
