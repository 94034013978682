import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import AdminLayout from "../components/layouts/Admin";
import AuthLayout from "../components/layouts/Auth";
export default function Router() {
  
  return (
    <div>
      <BrowserRouter>
        <Switch>
          <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
          <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
          <Redirect from="/" to="/auth/login" />
        </Switch>
      </BrowserRouter>
      ;
    </div>
  );
}
