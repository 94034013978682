import { useEffect, useRef, useState } from "react";
import {
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Table,
  Container,
  Row,
  Spinner,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import Swal from "sweetalert2";

import Header from "components/Headers/Header.js";
import db from "config/firebase";
import "./sidebar.css";
import { storeReviewsData } from "store/action";
import { storeReviewsEmail } from "store/action";
import { clearReduxData } from "store/action";

const Review = () => {
  const [review, setReview] = useState([]);
  const [loader, setLoader] = useState(false);
  const allData = useSelector((state) => state);
  const [user, setUser] = useState([]);
  const [modal, setModal] = useState(false);
  const [userName, setUserName] = useState();
  const [rating, setRating] = useState();
  const [comment, setComment] = useState();
  const [editId, setEditId] = useState();
  const [editIndex, setEditIndex] = useState();
  const [defaultrowCount, setDefaultRowCount] = useState();
  const [rowCount, setRowCount] = useState(0);
  const [pageNum, setPageNum] = useState(1);
  const [totalPage, setTotalPage] = useState(20);
  const [change, setChange] = useState(2);
  const [dropDownPage, setDropDownPage] = useState(1);

  const history = useHistory();
  const dispatch = useDispatch();
  const toggle = () => setModal(!modal);

  const UserName = useRef();
  const Rating = useRef();
  const Comments = useRef();

  let temp = [];
  useEffect(() => {
    if (allData.role !== "") {
      if (!allData.reviewsData?.length) {
        setLoader(true);
        db.collection("reviews")
          .get()
          .then((collections) => {
            const review = collections.docs.map((reviews) => ({
              ...reviews.data(),
              read: false,
            }));
            setReview(review);
            setDefaultRowCount(review.length);
            dispatch(storeReviewsData(review));
            setLoader(false);
          });
      } else {
        setReview(allData.reviewsData);
        setDefaultRowCount(allData.reviewsData.length);
        setLoader(false);
      }
    } else {
      history.push("/");
    }
    if (!allData.reviewsEmail?.length) {
      db.collection("users")
        .get()
        .then((collections) => {
          collections.forEach((doc) => {
            temp.push(doc.data());
          });
          setUser(temp);
          dispatch(storeReviewsEmail(temp));
        });
    } else {
      setUser(allData.reviewsEmail);
    }
  }, []);

  useEffect(() => {
    if (UserName.current) {
      UserName.current.value = userName;
    }
    if (Rating.current) {
      Rating.current.value = rating;
    }
    if (Comments.current) {
      Comments.current.value = comment;
    }
  }, [
    UserName.current,
    userName,
    Rating.current,
    rating,
    Comments.current,
    comment,
  ]);

  // const pagination = paginationFactory({
  //   page: 1,
  //   sizePerPage: 20,
  //   nextPageText: ">",
  //   prePageText: "<",
  //   showTotal: false,
  //   alwaysShowAllBtns: true,
  //   onSizePerPageChange: function (sizePerPage, page) {
  //     setPageNum(page);
  //     setTotalPage(sizePerPage)
  //   },

  //   onPageChange: function (page, sizePerPage) {
  //     setPageNum(page);
  //   },
  //   sizePerPageList:
  //     allData?.reviewsData?.length > 100
  //       ? [
  //           {
  //             text: "10",
  //             value: 10,
  //           },
  //           {
  //             text: "20",
  //             value: 20,
  //           },
  //           {
  //             text: "30",
  //             value: 30,
  //           },
  //           {
  //             text: "40",
  //             value: 40,
  //           },
  //           {
  //             text: "50",
  //             value: 50,
  //           },
  //           {
  //             text: "60",
  //             value: 60,
  //           },
  //           {
  //             text: "70",
  //             value: 70,
  //           },
  //           {
  //             text: "80",
  //             value: 80,
  //           },
  //           {
  //             text: "90",
  //             value: 90,
  //           },
  //           {
  //             text: "100",
  //             value: 100,
  //           },
  //           {
  //             text: "All",
  //             value: allData?.reviewsData?.length,
  //           },
  //         ]
  //       : allData?.reviewsData?.length < 100 && allData?.reviewsData?.length > 50
  //       ? [
  //           {
  //             text: "10",
  //             value: 10,
  //           },
  //           {
  //             text: "20",
  //             value: 20,
  //           },
  //           {
  //             text: "30",
  //             value: 30,
  //           },
  //           {
  //             text: "40",
  //             value: 40,
  //           },
  //           {
  //             text: "50",
  //             value: 50,
  //           },
  //           {
  //             text: "All",
  //             value: allData?.reviewsData?.length,
  //           },
  //         ]
  //       : allData?.reviewsData?.length < 50 && allData?.reviewsData?.length > 20
  //       ? [
  //           {
  //             text: "10",
  //             value: 10,
  //           },
  //           {
  //             text: "20",
  //             value: 20,
  //           },
  //           {
  //             text: "All",
  //             value: allData?.reviewsData?.length,
  //           },
  //         ]
  //       : [
  //           {
  //             text: "10",
  //             value: 10,
  //           },
  //           {
  //             text: "All",
  //             value: allData?.reviewsData?.length,
  //           },
  //         ],
  // });

  const pageListRenderer = ({ pages, onPageChange }) => {
    const pageWithoutIndication = pages.filter(
      (p) => typeof p.page !== "string"
    );
    return (
      <div>
        <UncontrolledDropdown id="page-dropdown">
          <DropdownToggle
            caret
            color="secondary"
            id="dropdownMenuButton"
            type="button"
          >
            {dropDownPage}
          </DropdownToggle>
          <DropdownMenu
            aria-labelledby="dropdownMenuButton"
            style={{
              maxHeight: 280,
              overflowY: "auto",
            }}
          >
            {pageWithoutIndication.map((p) => (
              <DropdownItem
                onClick={() => {
                  onPageChange(p.page);
                  setDropDownPage(p.page);
                }}
              >
                {p.page}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </UncontrolledDropdown>
      </div>
    );
  };

  const options = {
    pageListRenderer,
    sizePerPage: 20,
    paginationSize: allData?.userData?.length,

    onSizePerPageChange: function (sizePerPage, page) {
      setPageNum(page);
      setTotalPage(sizePerPage);
      setDropDownPage(1);
    },

    onPageChange: function (page, sizePerPage) {
      setPageNum(page);
    },

    sizePerPageList:
      allData?.reviewsData?.length > 100
        ? [
            {
              text: "10",
              value: 10,
            },
            {
              text: "20",
              value: 20,
            },
            {
              text: "30",
              value: 30,
            },
            {
              text: "40",
              value: 40,
            },
            {
              text: "50",
              value: 50,
            },
            {
              text: "60",
              value: 60,
            },
            {
              text: "70",
              value: 70,
            },
            {
              text: "80",
              value: 80,
            },
            {
              text: "90",
              value: 90,
            },
            {
              text: "100",
              value: 100,
            },
            {
              text: "All",
              value: allData?.reviewsData?.length,
            },
          ]
        : allData?.reviewsData?.length < 100 &&
          allData?.reviewsData?.length > 50
        ? [
            {
              text: "10",
              value: 10,
            },
            {
              text: "20",
              value: 20,
            },
            {
              text: "30",
              value: 30,
            },
            {
              text: "40",
              value: 40,
            },
            {
              text: "50",
              value: 50,
            },
            {
              text: "All",
              value: allData?.reviewsData?.length,
            },
          ]
        : allData?.reviewsData?.length < 50 && allData?.reviewsData?.length > 20
        ? [
            {
              text: "10",
              value: 10,
            },
            {
              text: "20",
              value: 20,
            },
            {
              text: "All",
              value: allData?.reviewsData?.length,
            },
          ]
        : [
            {
              text: "10",
              value: 10,
            },
            {
              text: "All",
              value: allData?.reviewsData?.length,
            },
          ],
  };
  const deleteData = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this data!",
      icon: "warning",
      dangerMode: true,
      showCancelButton: true,
      closeOnConfirm: false,
      closeOnCancel: false,
    }).then((result) => {
      if (result.isConfirmed) {
        let temp = review.filter((item) => item.docId !== id);
        setReview(temp);
        dispatch(storeReviewsData(temp));
        db.collection("reviews")
          .doc(id)
          .delete()
          .then(() => {
            Swal.fire({
              title: "Shortlisted!",
              text: "Data deleted successfully!",
              icon: "success",
            });
          })
          .catch((error) => {
            console.error("Error removing document: ", error);
          });
      } else {
        Swal.fire({
          title: "Cancelled",
          text: "Your data is safe :)",
          icon: "error",
        });
      }
    });
  };

  const editData = (id, userName, rating, comment, index) => {
    setEditId(id);
    setEditIndex(index);
    setUserName(userName);
    setRating(rating);
    setComment(comment);
    toggle();
  };

  const updateData = () => {
    db.collection("reviews")
      .doc(editId)
      .update({
        comment: Comments?.current?.value,
        rating: Rating?.current?.value,
        reviewerUsername: UserName?.current?.value,
      })
      .then(() => {
        let temp = [...review];
        temp[editIndex].reviewerUsername = UserName?.current?.value;
        temp[editIndex].rating = Rating?.current?.value;
        temp[editIndex].comment = Comments?.current?.value;
        setReview(temp);
        toggle();
        dispatch(storeReviewsData(temp));
      });
  };

  const columns = [
    {
      dataField: "email",
      text: "User Email",
      sort: true,
      filter: textFilter(),
    },
    {
      dataField: "reviewerUsername",
      text: "Listing",
      sort: true,
      filter: textFilter(),
    },
    {
      dataField: "rating",
      text: "Rating",
      sort: true,
      filter: textFilter(),
    },
    {
      dataField: "comment",
      text: "Comment",
      sort: true,
    },
    {
      dataField: "action",
      text: "Action",
    },
  ];

  const defaultSorted = [
    {
      dataField: "name",
      order: "desc",
    },
  ];

  const readMore = (id) => {
    let dup = [...review];

    dup.filter((i) => i.docId == id).map((d) => (d.read = !d.read));
    setReview(dup);
  };
  const handleDataChange = ({ dataSize }) => {
    if (dataSize == "0") {
      setRowCount(false);
      setDropDownPage(1);
    } else {
      setRowCount(dataSize);
      setDropDownPage(pageNum);
    }
  };
  const myData =
    review &&
    review?.map((r, index) => ({
      email: user.find((item) => item?.docId === r?.reviewerUid)
        ? user.find((item) => item?.docId === r?.reviewerUid) &&
          (user.find((item) => item?.docId === r?.reviewerUid)?.email
            ? user.find((item) => item?.docId === r?.reviewerUid)?.email
            : "N/A")
        : "N/A",
      reviewerUsername: r?.reviewerUsername,
      rating: r?.rating,
      comment:
        r?.read === true ? (
          <div className="show-less">
            {r?.comment}
            <button onClick={() => readMore(r.docId)}>Read Less</button>
          </div>
        ) : (
          <div>
            {r?.read === true ? r?.comment : ""}
            {r?.comment ? r?.comment.substr(0, 30) : "N/A"}
            {r?.comment === "" || r?.comment?.length < 30 ? (
              ""
            ) : (
              <button onClick={() => readMore(r.docId)}>...Read More</button>
            )}
          </div>
        ),
      action: (
        <UncontrolledDropdown>
          <DropdownToggle
            className="btn-icon-only text-light"
            href="#pablo"
            role="button"
            size="sm"
            color=""
            onClick={(e) => e.preventDefault()}
          >
            <i className="fas fa-ellipsis-v" />
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-arrow" right>
            <DropdownItem
              href="#pablo"
              onClick={() =>
                editData(
                  r.docId,
                  r.reviewerUsername,
                  r.rating,
                  r.comment,
                  index
                )
              }
            >
              Edit
            </DropdownItem>
            <DropdownItem href="#pablo" onClick={() => deleteData(r.docId)}>
              Delete
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      ),
    }));

  useEffect(() => {
    console.log({ change }, "-----");
  }, [change]);

  const refresh = () => {
    dispatch(clearReduxData());
    setLoader(true);
    db.collection("reviews")
    .get()
    .then((collections) => {
      const review = collections.docs.map((reviews) => ({
        ...reviews.data(),
        read: false,
      }));
      setReview(review);
      setDefaultRowCount(review.length);
      dispatch(storeReviewsData(review));
      setLoader(false);
    });
  
  };
  return (
    <>
      <Header />

      <Container className="mt--7" fluid>
        <Row id="review">
          <div className="col">
            <Card className="shadow">
            <CardHeader className="table-header" id="dektp_view">
                <h3 className="mb-0">
                  Review List{" "}
                  <button
                    onClick={refresh}
                    className="btn btn-primary py-1 px-1 ml-2"
                  >
                    Refresh
                  </button>
                </h3>

                {/* {rowCount > 0 || rowCount === false ? ( */}
                <div className="d-flex table-wrapper">
                  <h3 className="records-title">
                    {" "}
                    No. of Records:<span className="dyn_drop"></span>
                  </h3>

                  <h3 className="page-title">
                    Pages:
                    <span
                      className={
                        rowCount > 0 || rowCount === false
                          ? "dyn_drop_"
                          : "dyn_drop"
                      }
                    ></span>
                    /{" "}
                    {rowCount > 0 || rowCount === false
                      ? Math.ceil(rowCount / 20) == 0
                        ? "0"
                        : ` ${Math.ceil(rowCount / totalPage)}`
                      : Math.ceil(defaultrowCount / totalPage)}
                  </h3>
                  <h3 className="total-title">
                    {" "}
                    Total:{" "}
                    {rowCount > 0 || rowCount === false
                      ? rowCount
                        ? rowCount
                        : "0"
                      : defaultrowCount}
                  </h3>
                </div>
              </CardHeader>
              {/* //mobile view */}
              <CardHeader className="table-header" id="mob_view">
                <h3 className="mb-0 text-center">User Lists </h3>

                <h3 className="records-title mt-3 mb-3">
                  {" "}
                  No. of Records:<span className="dyn_drop"></span>
                </h3>

                <h3 className="page-title">
                  Pages:
                  <span
                    className={
                      rowCount > 0 || rowCount === false
                        ? "dyn_drop_"
                        : "dyn_drop"
                    }
                  ></span>
                  /{" "}
                  {rowCount > 0 || rowCount === false
                    ? Math.ceil(rowCount / 20) == 0
                      ? "0"
                      : ` ${Math.ceil(rowCount / totalPage)}`
                    : Math.ceil(defaultrowCount / totalPage)}
                </h3>
                <h3 className="total-title">
                  {" "}
                  Total:{" "}
                  {rowCount > 0 || rowCount === false
                    ? rowCount
                      ? rowCount
                      : "0"
                    : defaultrowCount}
                </h3>
                <button
                  onClick={refresh}
                  className="btn btn-primary py-1 px-1 ml-2"
                >
                  Refresh
                </button>
              </CardHeader>


              {loader ? (
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">User Email</th>
                      <th scope="col">Listing</th>
                      <th scope="col">Rating</th>
                      <th scope="col">Comment</th>
                      <th scope="col">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="text-center">
                      <td className="loader" colSpan={5}>
                        <Spinner className="text-primary">
                          <span className=" sr-only">Loading...</span>
                        </Spinner>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              ) : (
                <BootstrapTable
                  bootstrap4
                  keyField="id"
                  data={myData}
                  columns={columns}
                  defaultSorted={defaultSorted}
                  bordered={false}
                  pagination={paginationFactory(options)}
                  filter={filterFactory()}
                  onDataSizeChange={handleDataChange}
                />
              )}

              <CardFooter className="py-4"></CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          <h2>Edit</h2>
        </ModalHeader>
        <ModalBody>
          <Label>Username</Label>
          <Input type="text" innerRef={UserName} />
          <Label>Rating</Label>
          <Input type="number" innerRef={Rating} />
          <Label>Comment</Label>
          <Input type="text" innerRef={Comments} />
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={updateData}>
            Update
          </Button>{" "}
        </ModalFooter>
      </Modal>
    </>
  );
};

export default Review;
