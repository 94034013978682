const initialState = {
  role: "",
  displayName: "",
  userData: [],
  providerData: [],
  providerEmail: [],
  reviewsEmail: [],
  linkedData: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_DATA": {
      return {
        ...state,
        role: action.role,
        displayName: action.displayName,
      };
    }
    case "ADD_USER_DATA": {
      return {
        ...state,
        userData: action.userData,
      };
    }

    case "ADD_PROVIDER_DATA": {
      return {
        ...state,
        providerData: action.providerData,
      };
    }

    case "ADD_REVIEWS_DATA": {
      console.log(action.reviewsData);

      return {
        ...state,
        reviewsData: action.reviewsData,
      };
    }
    case "ADD_PROVIDER_EMAIL": {
      return {
        ...state,
        providerEmail: action.providerEmail,
      };
    }
    case "ADD_REVIEWS_EMAIL": {
      return {
        ...state,
        reviewsEmail: action.reviewsEmail,
      };
    }
    case "REMOVE_DATA": {
      return {
        ...state,
        role: "",
      };
    }

    case "UPDATE_LINKED_ACCOUNTS": {
      
      let dup = [...state.userData]
     
      let ind = dup.findIndex(item => item.docId === action.linkedData.docId)
      dup[ind] = action.linkedData
      
      return {
        ...state,
        userData: dup,
      };
    }
    case "CLEAR_REDUX_DATA": {
      return {
        ...state,
        userData: [],
        providerData: [],
        reviewsData: [],
      }
    }

    default: {
      return state;
    }
  }
};
export default reducer;
