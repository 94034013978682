import { useEffect, useRef, useState } from "react";
import {
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Table,
  Container,
  Row,
  Spinner,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
} from "reactstrap";
import "./sidebar.css";

// core components
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { BsGlobe, BsInstagram, BsFacebook } from "react-icons/bs";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import Swal from "sweetalert2";

import Header from "components/Headers/Header.js";
import TableModal from "./TableModal";
import QualificationModal from "./TableModal/QualificationModal";
import db from "config/firebase";
import "./sidebar.css";

import { storeProviderData } from "store/action";
import { storeProviderEmail } from "store/action";
import { clearReduxData } from "store/action";

const Providers = () => {
  const [providers, setProviders] = useState([]);
  const [loader, setLoader] = useState(false);
  const history = useHistory();
  const [user, setUser] = useState([]);
  const [modal, setModal] = useState(false);
  const [editId, setEditId] = useState();
  const [editClosingTime, setEditClosingTime] = useState();
  const [editDescription, setEditDescription] = useState();
  const [editHourlyRate, setEditHourlyRate] = useState();
  const [editLatitude, setEditLatitude] = useState();
  const [editLongitude, setEditLongitude] = useState();
  const [editOpeningTime, setEditOpeningTime] = useState();
  const [editName, setEditName] = useState();
  const [editSubCategory, setEditSubCategory] = useState();
  const [editTagline, setEditTagline] = useState();
  const [editUsername, setEditUsername] = useState();
  const [editIndex, setEditIndex] = useState();
  const [readmore, setReadMore] = useState(false);
  const [defaultrowCount, setDefaultRowCount] = useState();
  const [rowCount, setRowCount] = useState(0);
  const [pageNum, setPageNum] = useState(1);
  const [totalPage, setTotalPage] = useState(20);
  const [dropDownPage, setDropDownPage] = useState(1);
  const allData = useSelector((state) => state);
  const dispatch = useDispatch();
  const toggle = () => setModal(!modal);
  const userName = useRef();
  const Name = useRef();
  const OpeningHour = useRef();
  const ClosingHour = useRef();
  const description = useRef();
  const HourlyRate = useRef();
  const SubCategory = useRef();
  const Tagline = useRef();
  const Latitude = useRef();
  const Longitude = useRef();

  let temp = [];
  useEffect(() => {
    if (allData.role !== "") {
      if (!allData.providerData?.length) {
        setLoader(true);
        db.collection("providers")
          .get()
          .then((collections) => {
            const providers = collections.docs.map((provide) => ({
              ...provide.data(),
              read: false,
            }));
            setProviders(providers);
            setDefaultRowCount(providers.length);
            dispatch(storeProviderData(providers));
            setLoader(false);
          });
      } else {
        setProviders(allData.providerData);
        setDefaultRowCount(allData.providerData.length);

        setLoader(false);
      }
    } else {
      history.push("/");
    }
    if (!allData.providerEmail?.length) {
      db.collection("users")
        .get()
        .then((collections) => {
          collections.forEach((doc) => {
            temp.push(doc.data());
          });
          setUser(temp);
          dispatch(storeProviderEmail(temp));
        });
    } else {
      setUser(allData.providerEmail);
    }
  }, []);

  useEffect(() => {
    if (userName.current) {
      userName.current.value = editUsername;
    }
    if (Name.current) {
      Name.current.value = editName;
    }
    if (OpeningHour.current) {
      OpeningHour.current.value = editOpeningTime;
    }

    if (ClosingHour.current) {
      ClosingHour.current.value = editClosingTime;
    }

    if (description.current) {
      description.current.value = editDescription;
    }

    if (HourlyRate.current) {
      HourlyRate.current.value = editHourlyRate;
    }

    if (SubCategory.current) {
      SubCategory.current.value = editSubCategory;
    }

    if (Tagline.current) {
      Tagline.current.value = editTagline;
    }

    if (Latitude.current) {
      Latitude.current.value = editLatitude;
    }

    if (Longitude.current) {
      Longitude.current.value = editLongitude;
    }
  }, [
    userName.current,
    editUsername,
    Name.current,
    editName,
    OpeningHour.current,
    editOpeningTime,
    ClosingHour.current,
    editClosingTime,
    description.current,
    editDescription,
    HourlyRate,
    editHourlyRate,
    SubCategory.current,
    editSubCategory,
    Tagline.current,
    editTagline,
    Latitude.current,
    editLatitude,
  ]);

  const deleteData = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this data!",
      icon: "warning",
      dangerMode: true,
      showCancelButton: true,
      closeOnConfirm: false,
      closeOnCancel: false,
    }).then((result) => {
      if (result.isConfirmed) {
        let temp = providers.filter((item) => item.docId !== id);
        setProviders(temp);
        dispatch(storeProviderData(temp));
        db.collection("providers")
          .doc(id)
          .delete()
          .then(() => {
            Swal.fire({
              title: "Shortlisted!",
              text: "Data deleted successfully!",
              icon: "success",
            });
          })
          .catch((error) => {
            console.error("Error removing document: ", error);
          });
      } else {
        Swal.fire({
          title: "Cancelled",
          text: "Your data is safe :)",
          icon: "error",
        });
      }
    });
  };

  const editData = (
    id,
    closingTime,
    description,
    hourlyRate,
    latitude,
    longitude,
    openingTime,
    name,
    subCategory,
    tagline,
    username,
    index
  ) => {
    setEditId(id);
    setEditClosingTime(closingTime);
    setEditDescription(description);
    setEditHourlyRate(hourlyRate);
    setEditLatitude(latitude);
    setEditLongitude(longitude);
    setEditOpeningTime(openingTime);
    setEditName(name);
    setEditSubCategory(subCategory);
    setEditTagline(tagline);
    setEditUsername(username);
    setEditIndex(index);
    toggle();
  };

  const updateData = () => {
    db.collection("providers")
      .doc(editId)
      .update({
        openingTime: OpeningHour?.current?.value,
        closingTime: ClosingHour?.current?.value,
        description: description?.current?.value,
        hourlyRate: HourlyRate?.current?.value,
        longitude: Longitude?.current?.value,
        latitude: Latitude?.current?.value,
        name: Name?.current?.value,
        subCategory: SubCategory?.current?.value,
        tagline: Tagline?.current?.value,
        username: userName?.current?.value,
      })

      .then(() => {
        let temp = [...providers];
        temp[editIndex].openingTime = OpeningHour?.current?.value;
        temp[editIndex].closingTime = ClosingHour?.current?.value;
        temp[editIndex].description = description?.current?.value;
        temp[editIndex].hourlyRate = HourlyRate?.current?.value;
        temp[editIndex].longitude = Longitude?.current?.value;
        temp[editIndex].latitude = Latitude?.current?.value;
        temp[editIndex].name = Name?.current?.value;
        temp[editIndex].subCategory = SubCategory?.current?.value;
        temp[editIndex].tagline = Tagline?.current?.value;
        temp[editIndex].username = userName?.current?.value;
        setProviders(temp);
        dispatch(storeProviderData(temp));
        toggle();
      });
  };

  const columns = [
    {
      dataField: "email",
      text: "User Email",
      sort: true,
      filter: textFilter(),
    },
    {
      dataField: "category",
      text: "CATEGORY",
      sort: true,
    },
    {
      dataField: "subCategory",
      text: "SUB-CATEGORY",
      sort: true,
      filter: textFilter(),
    },
    {
      dataField: "name",
      text: "LISTING NAMES",
      sort: true,
      filter: textFilter(),
    },
    {
      dataField: "tagline",
      text: "TAG LINE",
      sort: true,
      filter: textFilter(),
    },
    {
      dataField: "description",
      text: "DESCRIPTION",
      sort: true,
    },
    {
      dataField: "spokenLanguage",
      text: "LANGUAGE SPOKEN",
      sort: true,
    },
    {
      dataField: "location",
      text: "LOCATION",
      sort: true,
    },
    {
      dataField: "operation",
      text: "Operation Hours",
      sort: true,
      filter: textFilter(),
      formatter: (cell, row) => cell,
      filterValue: (cell, row) => {
        <div className="myStyles">
          {cell === "Any" ? "Found" : "Not found"}
        </div>;
      },
    },
    {
      dataField: "qualificationUrls",
      text: "Qualification",
      sort: true,
    },
    {
      dataField: "bs_links",
      text: "Bussiness Social Links",
      sort: true,
    },
    {
      dataField: "images",
      text: "Images",
    },
    {
      dataField: "action",
      text: "Action",
    },
  ];
  const handleDataChange = ({ dataSize }) => {
    if (dataSize == "0") {
      setRowCount(false);
      setDropDownPage(1);
    } else {
      setRowCount(dataSize);
      setDropDownPage(pageNum);
    }
  };
  const defaultSorted = [
    {
      dataField: "name",
      order: "desc",
    },
  ];

  const readMore = (id) => {
    let dup = [...providers];

    dup.filter((i) => i.docId == id).map((d) => (d.read = !d.read));
    setProviders(dup);
  };

  // const pagination = paginationFactory({
  //   page: 1,
  //   sizePerPage: 20,
  //   nextPageText: ">",
  //   prePageText: "<",
  //   showTotal: false,
  //   alwaysShowAllBtns: true,
  //   onSizePerPageChange: function (sizePerPage, page) {
  //     setPageNum(page);
  //     setTotalPage(sizePerPage)
  //   },

  //   onPageChange: function (page, sizePerPage) {
  //     setPageNum(page);
  //   },
  //   sizePerPageList:
  //     allData?.providerData?.length > 100
  //       ? [
  //           {
  //             text: "10",
  //             value: 10,
  //           },
  //           {
  //             text: "20",
  //             value: 20,
  //           },
  //           {
  //             text: "30",
  //             value: 30,
  //           },
  //           {
  //             text: "40",
  //             value: 40,
  //           },
  //           {
  //             text: "50",
  //             value: 50,
  //           },
  //           {
  //             text: "60",
  //             value: 60,
  //           },
  //           {
  //             text: "70",
  //             value: 70,
  //           },
  //           {
  //             text: "80",
  //             value: 80,
  //           },
  //           {
  //             text: "90",
  //             value: 90,
  //           },
  //           {
  //             text: "100",
  //             value: 100,
  //           },
  //           {
  //             text: "All",
  //             value: allData?.providerData?.length,
  //           },
  //         ]
  //       : allData?.providerData?.length < 100 && allData?.providerData?.length > 50
  //       ? [
  //           {
  //             text: "10",
  //             value: 10,
  //           },
  //           {
  //             text: "20",
  //             value: 20,
  //           },
  //           {
  //             text: "10",
  //             value: 30,
  //           },
  //           {
  //             text: "10",
  //             value: 40,
  //           },
  //           {
  //             text: "10",
  //             value: 50,
  //           },
  //           {
  //             text: "All",
  //             value: allData?.providerData?.length,
  //           },
  //         ]
  //       : allData?.providerData?.length < 50 && allData?.providerData?.length > 20
  //       ? [
  //           {
  //             text: "10",
  //             value: 10,
  //           },
  //           {
  //             text: "20",
  //             value: 20,
  //           },
  //           {
  //             text: "All",
  //             value: allData?.providerData?.length,
  //           },
  //         ]
  //       : [
  //           {
  //             text: "10",
  //             value: 10,
  //           },
  //           {
  //             text: "All",
  //             value: allData?.providerData?.length,
  //           },
  //         ],
  // });

  const pageListRenderer = ({ pages, onPageChange }) => {
    const pageWithoutIndication = pages.filter(
      (p) => typeof p.page !== "string"
    );
    return (
      <div>
        <UncontrolledDropdown id="page-dropdown">
          <DropdownToggle
            caret
            color="secondary"
            id="dropdownMenuButton"
            type="button"
          >
            {dropDownPage}
          </DropdownToggle>
          <DropdownMenu
            aria-labelledby="dropdownMenuButton"
            style={{
              maxHeight: 280,
              overflowY: "auto",
            }}
          >
            {pageWithoutIndication.map((p) => (
              <DropdownItem
                onClick={() => {
                  onPageChange(p.page);
                  setDropDownPage(p.page);
                }}
              >
                {p.page}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </UncontrolledDropdown>
      </div>
    );
  };
  const options = {
    pageListRenderer,
    sizePerPage: 20,
    paginationSize: allData?.userData?.length,

    onSizePerPageChange: function (sizePerPage, page) {
      setPageNum(page);
      setTotalPage(sizePerPage);
      setDropDownPage(1);
    },

    onPageChange: function (page, sizePerPage) {
      setPageNum(page);
    },

    sizePerPageList:
      allData?.providerData?.length > 100
        ? [
            {
              text: "10",
              value: 10,
            },
            {
              text: "20",
              value: 20,
            },
            {
              text: "30",
              value: 30,
            },
            {
              text: "40",
              value: 40,
            },
            {
              text: "50",
              value: 50,
            },
            {
              text: "60",
              value: 60,
            },
            {
              text: "70",
              value: 70,
            },
            {
              text: "80",
              value: 80,
            },
            {
              text: "90",
              value: 90,
            },
            {
              text: "100",
              value: 100,
            },
            {
              text: "All",
              value: allData?.providerData?.length,
            },
          ]
        : allData?.providerData?.length < 100 &&
          allData?.providerData?.length > 50
        ? [
            {
              text: "10",
              value: 10,
            },
            {
              text: "20",
              value: 20,
            },
            {
              text: "10",
              value: 30,
            },
            {
              text: "10",
              value: 40,
            },
            {
              text: "10",
              value: 50,
            },
            {
              text: "All",
              value: allData?.providerData?.length,
            },
          ]
        : allData?.providerData?.length < 50 &&
          allData?.providerData?.length > 20
        ? [
            {
              text: "10",
              value: 10,
            },
            {
              text: "20",
              value: 20,
            },
            {
              text: "All",
              value: allData?.providerData?.length,
            },
          ]
        : [
            {
              text: "10",
              value: 10,
            },
            {
              text: "All",
              value: allData?.providerData?.length,
            },
          ],
  };
  const myData = providers.map((provider, index) => ({
    email: user.find((item) => item?.docId === provider?.uid)
      ? user.find((item) => item?.docId === provider?.uid) &&
        (user.find((item) => item?.docId === provider?.uid)?.email
          ? user.find((item) => item?.docId === provider?.uid)?.email
          : "N/A")
      : "",
    category: provider?.category ? provider?.category : "N/A",
    subCategory: provider?.subCategory ? provider?.subCategory : "N/A",
    name: provider?.name ? provider?.name : "N/A",
    tagline: provider?.tagline ? provider?.tagline : "N/A",
    description:
      provider?.read === true ? (
        <div className="show-less">
          {provider?.description}
          <button onClick={() => readMore(provider.docId)}>Read Less</button>
        </div>
      ) : (
        <div>
          {provider?.read === true ? provider?.description : ""}
          {provider?.description ? provider?.description.substr(0, 30) : "N/A"}
          {provider?.description === "" ||
          provider?.description?.length < 30 ? (
            ""
          ) : (
            <button onClick={() => readMore(provider.docId)}>
              ...Read More
            </button>
          )}
        </div>
      ),
    lang: provider?.lang ? provider?.lang : "N/A",
    spokenLanguage: provider?.spokenLanguage?.map((lang) => {
      return (
        <span key={lang} className="mr-2">
          {lang}
        </span>
      );
    }),
    location: (
      <span>
        {provider?.latitude ? provider?.latitude : "N/A"},{" "}
        {provider?.longitude ? provider?.longitude : "N/A"}
        <a
          className="ml-2"
          href={`https://www.google.com/maps?q=${provider?.latitude},${provider?.longitude}`}
          target="_blank"
        >
          Show On Map
        </a>
      </span>
    ),
    operation: (
      <span>
        Opening:
        {provider?.openingTime ? provider?.openingTime : "N/A"}
        Closing:
        {provider?.closingTime ? provider?.closingTime : "N/A"}
        Hourly:
        {provider?.hourlyRate ? provider?.hourlyRate : "N/A"}
      </span>
      //   provider?.openingTime ? provider?.openingTime : "N/A",

      //  <strong>{provider?.closingTime ? provider?.closingTime : "N/A" } </strong>

      //   // provider?.hourlyRate ? provider?.hourlyRate : "N/A"
    ),
    qualificationUrls:
      provider?.qualificationUrls?.length > 0 ? (
        <QualificationModal imageUrl={provider?.qualificationUrls} />
      ) : (
        "N/A"
      ),
    bs_links: provider?.linktofacebook ? (
      <a
        className="mr-2"
        href={`https://www.facebook.com/${provider.linktofacebook}`}
        target="_blank"
      >
        <BsFacebook size={22} />
      </a>
    ) : provider?.linktoinstagram ? (
      <a
        className="mr-2"
        href={`https://www.instagram.com/${provider.linktoinstagram}`}
        target="_blank"
      >
        <BsInstagram size={22} color={"#e978b9"} />
      </a>
    ) : provider?.linktowebsite ? (
      <a href={provider.linktowebsite} target="_blank">
        <BsGlobe size={22} color={"rgb(65 73 75)"} />
      </a>
    ) : (
      "N/A"
    ),
    images: provider?.urls ? <TableModal imageUrl={provider?.urls} /> : "N/A",
    action: (
      <UncontrolledDropdown>
        <DropdownToggle
          className="btn-icon-only text-light"
          href="#pablo"
          role="button"
          size="sm"
          color=""
          onClick={(e) => e.preventDefault()}
        >
          <i className="fas fa-ellipsis-v" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-arrow" right>
          <DropdownItem
            href="#pablo"
            onClick={() =>
              editData(
                provider?.docId,
                provider?.closingTime,
                provider?.description,
                provider?.hourlyRate,
                provider?.latitude,
                provider?.longitude,
                provider?.openingTime,
                provider?.name,
                provider?.subCategory,
                provider?.tagline,
                provider?.username,
                index
              )
            }
          >
            Edit
          </DropdownItem>
          <DropdownItem
            href="#pablo"
            onClick={() => deleteData(provider?.docId)}
          >
            Delete
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    ),
  }));

  const refresh = () => {
    dispatch(clearReduxData());
    setLoader(true);
    db.collection("providers")
      .get()
      .then((collections) => {
        const providers = collections.docs.map((provide) => ({
          ...provide.data(),
          read: false,
        }));
        setProviders(providers);
        setDefaultRowCount(providers.length);
        dispatch(storeProviderData(providers));
        setLoader(false);
      });
  };
  let total = Math.ceil(rowCount / 20);
  let onPage = Math.ceil(rowCount / total);

  let defaultTotal = Math.ceil(defaultrowCount / 20);
  let defaultOnPage = Math.ceil(defaultrowCount / defaultTotal);
  return (
    <>
      <Header />

      <Container className="mt--7" fluid>
        <Row id="provider">
          <div className="col">
            <Card className="shadow">
              <CardHeader className="table-header" id="dektp_view">
                <h3 className="mb-0">
                  Provider List{" "}
                  <button
                    onClick={refresh}
                    className="btn btn-primary py-1 px-1 ml-2"
                  >
                    Refresh
                  </button>
                </h3>

                {/* {rowCount > 0 || rowCount === false ? ( */}
                <div className="d-flex table-wrapper">
                  <h3 className="records-title">
                    {" "}
                    No. of Records:<span className="dyn_drop"></span>
                  </h3>

                  <h3 className="page-title">
                    Pages:
                    <span
                      className={
                        rowCount > 0 || rowCount === false
                          ? "dyn_drop_"
                          : "dyn_drop"
                      }
                    ></span>
                    /{" "}
                    {rowCount > 0 || rowCount === false
                      ? Math.ceil(rowCount / 20) == 0
                        ? "0"
                        : ` ${Math.ceil(rowCount / totalPage)}`
                      : Math.ceil(defaultrowCount / totalPage)}
                  </h3>
                  <h3 className="total-title">
                    {" "}
                    Total:{" "}
                    {rowCount > 0 || rowCount === false
                      ? rowCount
                        ? rowCount
                        : "0"
                      : defaultrowCount}
                  </h3>
                </div>
              </CardHeader>
              {/* //mobile view */}
              <CardHeader className="table-header" id="mob_view">
                <h3 className="mb-0 text-center">User Lists </h3>

                <h3 className="records-title mt-3 mb-3">
                  {" "}
                  No. of Records:<span className="dyn_drop"></span>
                </h3>

                <h3 className="page-title">
                  Pages:
                  <span
                    className={
                      rowCount > 0 || rowCount === false
                        ? "dyn_drop_"
                        : "dyn_drop"
                    }
                  ></span>
                  /{" "}
                  {rowCount > 0 || rowCount === false
                    ? Math.ceil(rowCount / 20) == 0
                      ? "0"
                      : ` ${Math.ceil(rowCount / totalPage)}`
                    : Math.ceil(defaultrowCount / totalPage)}
                </h3>
                <h3 className="total-title">
                  {" "}
                  Total:{" "}
                  {rowCount > 0 || rowCount === false
                    ? rowCount
                      ? rowCount
                      : "0"
                    : defaultrowCount}
                </h3>
                <button
                  onClick={refresh}
                  className="btn btn-primary py-1 px-1 ml-2"
                >
                  Refresh
                </button>
              </CardHeader>

              {loader ? (
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">User Email</th>
                      <th scope="col">Listing</th>
                      <th scope="col">Rating</th>
                      <th scope="col">Comment</th>
                      <th scope="col">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="text-center">
                      <td className="loader" colSpan={5}>
                        <Spinner className="text-primary">
                          <span className=" sr-only">Loading...</span>
                        </Spinner>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              ) : (
                <BootstrapTable
                  bootstrap4
                  keyField="id"
                  data={myData}
                  columns={columns}
                  defaultSorted={defaultSorted}
                  bordered={false}
                  pagination={paginationFactory(options)}
                  onDataSizeChange={handleDataChange}
                  filter={filterFactory()}
                />
              )}

              <CardFooter className="py-4"></CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          <h2>Edit</h2>
        </ModalHeader>
        <ModalBody>
          <Label>Username</Label>
          <Input type="text" innerRef={userName} />
          <Label>Name</Label>
          <Input type="text" innerRef={Name} />
          <Label>Opening Hour</Label>
          <Input type="text" innerRef={OpeningHour} />
          <Label>Closing Hour</Label>
          <Input type="text" innerRef={ClosingHour} />
          <Label>Description</Label>
          <Input type="text" innerRef={description} />
          <Label>Hourly Rate</Label>
          <Input type="text" innerRef={HourlyRate} />
          <Label>Sub Category</Label>
          <Input type="text" innerRef={SubCategory} />
          <Label>Tagline</Label>
          <Input type="text" innerRef={Tagline} />
          <Label>Latitude</Label>
          <Input type="text" innerRef={Latitude} />
          <Label>Longitude</Label>
          <Input type="text" innerRef={Longitude} />
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={updateData}>
            Update
          </Button>{" "}
        </ModalFooter>
      </Modal>
    </>
  );
};

export default Providers;
