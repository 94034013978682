import { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Col,
  Alert,
  Spinner,
} from "reactstrap";
import Swal from "sweetalert2";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import { signin, checkRole } from "config/firebase";
import { storeData, clearReduxData } from "store/action";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState();
  const [emptyFields, setEmptyFields] = useState(false);
  const [loader, setLoader] = useState(false);
  const allData = useSelector((state) => state);

  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (allData.role !== "") {
      history.push("/admin/users");
    }
  }, []);

  const userSignin = async (e) => {
    setLoader(true);
    e.preventDefault();
    if (email && password) {
      try {
        await signin(email, password);
        let res = await checkRole(email);
        if (res?.docs?.length) {
          res.docs.forEach((doc) => {
            if (doc.data().role === "Admin") {
              Swal.fire({
                icon: "success",
                text: "Signin Successfully",
              });
              dispatch(storeData("admin", doc.data().displayName));
              dispatch(clearReduxData())
              history.push("/admin/users");
            } else {
              Swal.fire({
                icon: "error",
                text: "You are not eligible to access admin panel",
              });
            }
          });
        } else {
          Swal.fire({
            icon: "error",
            text: "You are not eligible to access admin panel",
          });
        }
        setLoader(false);
      } catch (error) {
        Swal.fire({
          icon: "error",
          text: error.message,
        });
        setLoader(false);
      }
      setLoader(false);
    } else {
      setEmptyFields(true);
      setLoader(false);
    }
  };

  return (
    <>
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center text-muted mb-4">
              <small>Sign in with credentials</small>
            </div>
            <Form role="form" onSubmit={userSignin}>
              <FormGroup className="mb-3">
                {emptyFields && (
                  <Alert color="warning">
                    <strong>Please fill out all fields!</strong>
                  </Alert>
                )}

                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Email"
                    type="email"
                    autoComplete="new-email"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Password"
                    type="password"
                    autoComplete="new-password"
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                  />
                </InputGroup>
              </FormGroup>

              <div className="text-center">
                <Button
                  className="my-4"
                  color="primary"
                  type="submit"
                  disabled={loader}
                >
                  {loader ? <Spinner size="sm" color="light" /> : "Sign in"}
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default Login;
