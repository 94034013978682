import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyAnoObWD2KrMPSyuJQX8pdTCe61HgXZGWI",
  authDomain: "zonar-app-prod.firebaseapp.com",
  databaseURL: "https://zonar-app-prod.firebaseio.com",
  projectId: "zonar-app-prod",
  storageBucket: "zonar-app-prod.appspot.com",
  messagingSenderId: "342170682786",
  appId: "1:342170682786:web:8fee08eafe2b9417c15efb",
  measurementId: "G-VDPVYKJB9D",
};
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}
const db = firebase.firestore();

export let checkRole = (email) => {
  return db.collection("users").where("email", "==", email).get();
};

export const signin = (email, password) => {
  return firebase.auth().signInWithEmailAndPassword(email, password);
};

const signout = () => {
  return firebase.auth().signOut();
};

export default db;
export { signout };
